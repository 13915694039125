var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"level") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hour") : depth0), depth0))
    + "</td>\n            <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"month") : depth0), depth0))
    + "</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table d-none d-md-block\">\n    <tbody>\n        <tr>\n            <th>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"shortName") : stack1), depth0))
    + "</th>\n            <th colspan=\"2\">до 3 месяцев</th>\n            <th colspan=\"2\">более 3 месяцев <span class=\"accent-plash\">- 5%</span></th>\n        </tr>\n        <tr>\n            <td>Уровень</td>\n            <td>час</td>\n            <td>месяц</td>\n            <td>час</td>\n            <td>месяц</td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"levels") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":23,"column":17}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"useData":true});