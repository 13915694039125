/** Класс создания отложенной загрузки для бэкграундов. */
class LoadingLazyBackground {
    /**
     * Конструктор класса.
     *
     * @param {HTMLElement} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.lazyBackgroundObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    this.lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        this.lazyBackgroundObserver.observe(this.el);
    }
}

document.querySelectorAll(".js-lazy-background").forEach(el => {
    new LoadingLazyBackground(el);
})