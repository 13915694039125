/** Базовый класс поля ввода. */
export class Input {
    /**
     * Создает компонент ввода текста.
     *
     * @param {object} props Параметры компонента.
     * @param {HTMLElement} props.container Родительский элемент.
     * @param {HTMLInputElement} props.input Элемент ввода.
     */
    constructor({container, input}) {
        this.el = container;
        this.input = input;
        this.updateIsFilledStatus();
        this.bindEventListeners();
    }

    /**
     * Вешает коллбеки событий на элементы компонента.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.input.addEventListener("input", this.onInput.bind(this));
    }

    /**
     * Получает введенное значение.
     *
     * @returns {string} Введеное значение.
     */
    getValue() {
        return this.input.value;
    }

    /**
     * Проверяет наличие лейбла.
     *
     * @returns {HTMLElement} Лейбл поля ввода.
     */
    hasLabel() {
        return this.el.querySelector(".form-field__label");
    }

    /**
     * Обновляет состояние ввода, которое говорит, введено ли значение в поле, или нет.
     *
     * @returns {void}
     */
    updateIsFilledStatus() {
        if (this.getValue() && this.hasLabel()) {
            this.el.classList.add("filled");
        } else {
            this.el.classList.remove("filled");
        }
    }

    /**
     * Колбэк обработки события 'input'.
     *
     * @returns {void}
     */
    onInput() {
        this.updateIsFilledStatus();
    }
}
