/**
 * Компонент загрузки файлов.
 */
const BYTE = 1024;
class UploadFile {
    /**
     * Создается компонент.
     *
     * @param {Element} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.dt = new DataTransfer(); 
        this.findElements();
        this.bindEventListeners();
    }

    /**
     * Поиск элементов.
     *
     * @returns {void}
     */
    findElements() {
        this.form = this.el.closest("form");
        this.fileBlock = this.el.closest(".js-file-block");
        this.fileLabel = this.fileBlock.querySelector(".js-file-label");
        this.fileIcon = this.fileBlock.querySelector(".js-file-icon");
        this.dropArea = this.fileBlock.querySelector(".js-drop-area");
        this.filesList = this.fileBlock.querySelector(".js-files-list")
        this.files = this.filesList.children;
    }

    /**
     * Отслеживание событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.el.addEventListener("change", (e) => this.onChangeInputFile(e));

        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("js-file-delete")) {
                this.removeFile(e);
                this.updateTextOnInput();
            }
        });

        document.addEventListener("dragstart", (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.dropArea.classList.remove("hidden");
        });

        document.addEventListener("dragover", (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.dropArea.classList.remove("hidden");
        });
    
        document.addEventListener("drop", (e) => {
            e.preventDefault();
            e.stopPropagation();

            e.dataTransfer.getData("name");
            e.dataTransfer.getData("size");
            this.onChangeInputFile(e);
            this.dropArea.classList.add("hidden");
        });
    }

    /**
     * Обновлять внешний вид инпута с прекреплением файла.
     * Если файл/файлы добавлены, выводить "Добавить ещё".
     * Если файлов нет или все удалены, выводить "Прикрепить файл".
     *
     * @returns {void}
     */
    updateTextOnInput() {
        if (this.files.length > 0) {
            this.fileLabel.textContent = "Добавить ещё";
            this.fileIcon.classList.remove("form-field__icon--file");
            this.fileIcon.classList.add("form-field__icon--add");
        } else {
            this.fileLabel.textContent = "Прикрепить файл";
            this.fileIcon.classList.remove("form-field__icon--add");
            this.fileIcon.classList.add("form-field__icon--file");
        }
    }

    /**
     * Удалять файл на клик по иконке корзины.
     *
     * @param {Event} e Событие, происходящее при клике на иконку корзины у файла.
     * @returns {void}
     */
    removeFile(e) {
        const btnDelete = e.target;
        const file = btnDelete.closest(".js-file-item");
        file.remove();
    }

    /**
     * Действия при изменения поля с добавлением файла.
     * Вывод добавленых файлов на страницу.
     *
     * @param {Event} e Событие при изменения поля с добавлением файла.
     * @returns {void}
     */
    onChangeInputFile(e) {
        this.fileBlock.classList.remove("filled");

        let files = e.target.files || e.dataTransfer.files;

        for(let i = 0; i < files.length; i++) {
            let file = files.item(i);
            const {name: fileName, size} = file;
            const fileSize = (size / BYTE).toFixed(1);
                
            const fileItem = ` 
                <div class="form-files__item js-file-item">
                    <div class="form-files__item-info">
                        <div class="form-files__item-size">Файл, <span class="js-file-size">${fileSize} KB</span></div>
                        <div class="form-files__item-name js-file-name">${fileName}</div>
                    </div>
                    <span class="form-files__item-delete js-file-delete"></span>
                </div>`;
            this.filesList.insertAdjacentHTML("beforeEnd", fileItem);
        }            

        this.updateTextOnInput();
    }
}

document.querySelectorAll("input[type='file']").forEach((elem) => new UploadFile(elem));