import Swiper from "swiper";
import {Autoplay} from "swiper/modules";
/** Класс слайдера со стеком. */
class StackSlider {
    /**
     * Конструктор класса.
     *
     * @param {HTMLElement} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.createSlider();
    }

    /**
     * Инициализация слайдера.
     * 
     * @returns {void} 
     */
    createSlider() {
        new Swiper(this.el, {
            modules: [Autoplay],
            slidesPerView: "auto",
            loop: true,
            watchSlidesProgress: true,
            speed: 5000,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
                waitForTransition: false
            },
        });
    }
}

window.addEventListener("load", () => {
    document
        .querySelectorAll(".js-stack-slider")
        .forEach(slider => new StackSlider(slider));
});