// import {FileInput} from "@main/components/form/file-input";
import {Input} from "@main/components/form/input";
import {PhoneInput} from "@main/components/form/phone-input";

/**
 * Инициализируются компоненты ввода.
 *
 * @param {Element} element HTML элемент для поиска.
 * @returns {void}
 */
export const initFormFields = (element) => {
    element.querySelectorAll(".js-form-field").forEach((el) => {
        const input = el.querySelector(".js-form-field-input");

        switch (input.type) {
            // case "file":
            //     return new FileInput({container: el, input});

            case "tel":
                return new PhoneInput({container: el, input});

            default:
                return new Input({container: el, input});
        }
    });
}
initFormFields(document);
