import axios from "@main/axios";
import {EMAIL_REGEX} from "@main/consts/regex";

/** Компонент для роботы с форомой(отправка, валидация). */
export class Form {
    static MAX_PHONE_LENGTH = 18;
    /**
     * @type {{Boolean}} результат валидации инпута.
     * @private
     */
    result;

    /**
     * Создает компонент выбора файла.
     *
     * @param {HTMLFormElement} form Элемент формы.
     */
    constructor(form) {
        this.el = form;
        this.url = this.el.dataset.url;
        this.findElements();
        this.bindEventListeners();
    }

    /**
     * Поиск элементов компонента.
     *
     * @returns {void}
     */
    findElements() {
        this.fields = [...this.el.querySelectorAll(".js-form-field")].map((field) => ({
            node: field,
            input: field.querySelector(".js-form-field-input"),
        }));
    }

    /**
     * Скрывает сообщение об ошибке.
     *
     * @param {HTMLDivElement} field Контейнер поля вода.
     * @returns {void}
     */
    hideFieldError(field) {
        field.classList.remove("error");
    }

    /**
     * Привязка обработчиков событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.el.addEventListener("submit", (e) => e.preventDefault());

        this.bindInputListeners();
    }

    /**
     * Привязка обработчиков событий ввода в поля.
     *
     * @returns {void}
     */
    bindInputListeners() {
        this.fields.forEach((field) => {
            field.input.addEventListener("input", () => this.hideFieldError(field.node));
            field.input.addEventListener("paste", () => this.hideFieldError(field.node));
        });
    }

    /**
     * Определяет валидность ввода в форме.
     *
     * @returns {boolean} Флаг валидности введенной формы.
     */
    get isValid() {
        let isValid = true;
        this.fields.forEach((field) => {
            if (!this.validateField(field.input)) {
                isValid = false;
                field.node.classList.add("error");
            }
        });

        return isValid;
    }

    /**
     * Проверка валидации поля ввода.
     *
     * @param {HTMLInputElement} input Инпут компонента формы.
     * @returns {boolean} Флаг валидности заполнения поля.
     */
    validateField(input) {
        if (input.pattern) {
            this.result = Form.isValidLength(input);
        } else {
            // если нет паттерна и поле не обязательное просто возвращаем true
            if (!input.required) {
                return true;
            }

            this.result = true;
        }

        switch (input.type) {
            case "file":
                this.result = Form.isValidFile(input);
                break;

            case "tel":
                this.result = Form.isValidPhone(input);
                break;

            case "email":
                this.result = Form.isValidMail(input);
                break;
                
            default:
                this.result = !!input.value;
                break;
        }

        return this.result;
    }

    /**
     * Отправка формы.
     *
     * @param {HTMLFormElement} formNode DOM узел формы.
     * @param {string} url Url отправки формы.
     * @returns {object} Ответ сервера в формате, согласному std.
     */
    static async fetchForm(formNode, url) {
        const data = new FormData(formNode);
        const {data: response} = await axios.post(url, data);
        return response;
    }

    /**
     * Проверка валидности номера телефона.
     *
     * @param {HTMLInputElement} phoneInput Инпут телефона.
     * @returns {boolean} Ввод телефона валидный.
     */
    static isValidPhone(phoneInput) {
        return phoneInput.value.length === Form.MAX_PHONE_LENGTH;
    }

    /**
     * Проверка валидности электронной почты.
     *
     * @param {HTMLInputElement} mailInput Инпут почты.
     * @returns {boolean} Валидность ввода почты.
     */
    static isValidMail(mailInput) {
        return EMAIL_REGEX.test(mailInput.value);
    }

    /**
     * Проверка валидности выбора файлов.
     *
     * @param {HTMLInputElement} fileInput Инпут файла.
     * @returns {boolean} Валидность выбора файла.
     */
    static isValidFile(fileInput) {
        return !!fileInput.files.length;
    }

    /**
     * Проверка валидности длины строки.
     *
     * @param {HTMLInputElement} input Инпут.
     * @returns {boolean} Валидность длины строки.
     */
    static isValidLength(input) {
        if (input.maxLength && input.maxLength > 0) {
            if ( input.value.length > input.maxLength) {
                return false;
            }
        }

        if (input.minLength && input.minLength > 0) {
            if ( input.value.length < input.minLength) {
                return false;
            }
        }
        return true;
    }
}
