const ANIMATION_TIME = 350;
/** Компонент модала. */
export class Modal {
    isOpen = false
    /**
     * Создаёт компонент модала.
     *
     * @param {HTMLElement} el Родительский элемент модала (элемент заднего фона).
     */
    constructor(el) {
        this.el = el;
        this.findElements();
        this.bindEventListeners();
    }

    /**
     * Находит элементы модала.
     *
     * @returns {void}
     */
    findElements() {
        this.closeButton = this.el.querySelector(".js-modal-close");
    }

    /**
     * Вешает колбеки закрытия модала.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.el.addEventListener("click", (e) => {
            if (!e.target.closest(".js-modal-dialog")) {
                Modal.close(this.el);
            }
        });

        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("js-modal-close")) {
                Modal.close(this.el);
            }
        });

        document.addEventListener("keydown", (e) => {
            const keyName = e.key;
            if (keyName === "Escape" && this.el.classList.contains("visible")) {
                Modal.close(this.el);
            }
        });
    }

    /**
     * Закрывает модал.
     *
     * @param {HTMLElement} modal Главный элемент модала.
     * @returns {void}
     */
    static close(modal) {
        if (!this.isOpen) {
            return;
        }
        
        modal.classList.add("animate__fadeOut");
        modal.classList.remove("animate__fadeIn");
        setTimeout(() => {
            modal.classList.remove("visible");
        }, ANIMATION_TIME);
        document.body.classList.remove("prevent-scroll");

        if (document.querySelector(".js-header").classList.contains("is-menu-open")) {
            document.body.classList.add("prevent-scroll");
        }

        if (modal.querySelector(".is-send")) {
            location.reload();
        }
        
        this.isOpen = false;
    }

    /**
     * Открывает модал.
     *
     * @param {Element} modal Главный элемент модала.
     * @returns {void}
     */
    static open(modal) {
        modal.classList.add("visible");
        modal.classList.remove("animate__fadeOut");
        modal.classList.add("animate__fadeIn");
        document.body.classList.add("prevent-scroll");
        modal.querySelector(".js-modal-top-line").scrollIntoView();
        this.isOpen = true;
    }
}

document.querySelectorAll(".js-modal").forEach((el) => new Modal(el));