const TRANSITION_TIME = "0.4s";

/**
 * Компонент аккордеона.
 */
export class Accordion {
    /**
     * Создает компонент аккордеона.
     *
     * @param {HTMLElement} el Элемент аккордеона.
     */
    constructor(el) {
        this.el = el;
        this.accordionHeaders = this.el.querySelectorAll(".js-accordion-header");
        this.contents = this.el.querySelectorAll(".js-accordion-body");
        this.bindEventListeners();
    }

    /**
     * Отслеживание событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.accordionHeaders.forEach(btn =>
            btn.addEventListener("click", this.accordionBtnClickHandler)
        );
    }

    /**
     * Переключение раскрытия аккордеона на клик.
     * Удаление активного класса у соседних аккордеонов.
     *
     * @param {Event} e Событие, происходящее при клике на аккордеон.
     * @returns {void}
     */
    accordionBtnClickHandler = (e) => {
        e.preventDefault();
        const currentBtn = e.target.closest(".js-accordion-header");
        const parentItem = currentBtn.parentElement;
        const currentContent = parentItem.querySelector(".js-accordion-body");
        currentContent.style.transition = TRANSITION_TIME;

        parentItem.classList.toggle("opened");

        const children = [...parentItem.parentElement.children];
        const siblings = children.filter((child) => child !== parentItem);
        siblings.forEach(sibling => {
            if (sibling.classList.contains("opened")) {
                sibling.classList.remove("opened");
                sibling.querySelector(".js-accordion-body").style.maxHeight = 0;
            }
        });

        if (parentItem.classList.contains("opened")) {
            currentContent.style.maxHeight = `${currentContent.scrollHeight}px`;
        } else {
            currentContent.style.maxHeight = 0;
        }
    };
}

document.querySelectorAll(".js-accordion").forEach(item => new Accordion(item));