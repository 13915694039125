import {Modal} from "@main/components/modal";
import {Form} from "@main/components/form";
import {isEnvDisableCaptcha} from "@main/utils/disable-captcha";
const TARGET_ID = 54304722;
/**
 * Компонент модального окна "Обсудить проект".
 */
class RequestBecomeClient {
    /**
     * Создает компонент.
     *
     * @param {HTMLElement} modal Модальное окно.
     */
    constructor(modal) {
        this.modal = modal;
         
        this.findElements();
        this.bindEventlisteners();
    }

    /**
     * Находит элементы компонента.
     *
     * @returns {void}
     */
    findElements() {
        this.formElement = this.modal.querySelector(".js-become-client-form");        
        this.form = new Form(this.formElement);
        this.btnSubmit = this.formElement.querySelector("button[type='submit']");
        this.firstField = this.formElement.querySelector(".js-form-first-field");
        this.modalDialog = this.modal.querySelector(".js-modal-dialog");
        this.modalMessage = this.modal.querySelector(".js-modal-message");
        this.modalMessageTitle = this.modalMessage.querySelector(".js-modal-title-message");
        this.modalMessageText = this.modalMessage.querySelector(".js-modal-text-message");
        this.modalMessageButton = this.modalMessage.querySelector(".js-modal-button-message");
    }

    /**
     * Привязывает колбеки событый.
     *
     * @returns {void}
     */
    bindEventlisteners() {
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("js-open-become-client-modal") || 
                e.target.closest(".js-open-become-client-modal")) {
                this.showModal();
            }
        });

        this.btnSubmit.addEventListener("click", (e) => {
            this.btnSubmit.focus();
            this.submitForm(e);
        })
    }

    /**
     * Отправка формы с показом результата.
     *
     * @param {Event} e Событие, происходящее при отправке формы.
     * @returns {void}
     */
    submitForm = async (e) => {
        e.preventDefault();
        if (!this.form.isValid) {
            this.firstField.scrollIntoView({behavior: "smooth", block: "start"}); 
            return;
        }
                
        if (!isEnvDisableCaptcha(process.env)) {
            const token = await new Promise((resolve) => {
                grecaptcha.ready(async () => {
                // noinspection JSVoidFunctionReturnValueUsed
                    resolve(await grecaptcha.execute(window.recaptchaKey, {
                        action: "submit",
                    }));
                });
            });

            this.formElement.querySelector("[name=g-recaptcha-response]").setAttribute("value", token);
        }

        if (typeof ym !== "undefined") {
            ym(TARGET_ID, "reachGoal", "otpravka");
        }

        try {
            const response = await Form.fetchForm(this.formElement, this.form.url);
            if (!response.success) {
                this.modalMessageTitle.innerHTML = response.error;
                this.modalMessageText.innerHTML = "Попробуйте ещё раз.";
                this.modalMessageButton.innerHTML = "Закрыть";
            } 
        } catch {
            this.modalMessageTitle.innerHTML = "Извините, произошла ошибка.";
            this.modalMessageText.innerHTML = "Попробуйте ещё раз.";
            this.modalMessageButton.innerHTML = "Закрыть";
        }

        this.modalDialog.classList.add("is-send");
        this.modalMessage.scrollIntoView();
    }

    /**
     * Показать окно "Обсудить проект".
     *
     * @returns {void}
     */
    showModal() {
        Modal.open(this.modal);
    }
}

new RequestBecomeClient(document.querySelector(".js-modal[data-modal-name='become-client']"));
