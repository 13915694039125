import "@styles/main.scss";

import "@main/utils/global";

import {App} from "@main/app";

window.app = new App();
window.app.run();

import "lazysizes";
const WOW = require("wowjs"); 
window.wow = new WOW.WOW({live: false, animateClass: "animate__animated", scrollContainer: "body"});
window.wow.init();
import "@main/components/form/index";
import "@main/components/form";
import "@main/components/sliders/";
import "@main/components/modal";
import "@main/components/tabs";
import "@main/components/form-request";
import "@main/components/upload-file";
import "@main/components/header-fixed";
import "@main/components/loading-lazy-background";
import "@main/components/calculator";
import "@main/components/table-of-specialists";
import "@main/components/accordion";
import "@main/components/request-become-client";