import {isJsonMap} from "@std/guards";

export abstract class App {
    protected config: JsonMap = {};

    protected constructor(config: JsonMap = {}) {
        this.loadConfig(config);
    }

    public abstract run(): void;

    public loadConfig(config: JsonMap): void {
        this.config = config;
    }

    public getOption(optionName: string, defaultValue?: any): JsonValue {
        const optionPath = optionName.split(".");

        let option: JsonValue = this.config;

        for (const key of optionPath) {
            if (isJsonMap(option)) {
                option = option[key];
            } else if (defaultValue) {
                return defaultValue;
            } else {
                throw new Error(`Опция ${optionName} не найдена.`);
            }
        }

        return option;
    }
}