const HEADER_PADDING_BOTTOM = 100;
const ANIMATION_TIME = 250;

/** Компонент фиксированной шапки. */
class HeaderFixed {  
    scrollPos = 0 

    clone

    /**
     * Конструктор класса.
     *
     * @param {HTMLElement} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.header = this.el.parentElement;       
        this.createCloneHeader(); 
        this.findStaticElements();
        this.bindEventListeners();
    }

    /**
     * Поиск неизменных элементов.
     *
     * @returns {void}
     */
    findStaticElements() {
        this.logoMain = this.clone.querySelector(".js-header-logo");
        this.logoMini = this.clone.querySelector(".js-header-logo-mini");
    }

    /**
     * Прослушивание событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        document.body.addEventListener("scroll", this.toggleFixedMenuVisibility.bind(this));
    }

    /**
     * Узнаем ширину полосы прокрутки.
     *
     * @returns {void}
     */
    getScrollbarWidth() {
        let div = document.createElement("div");

        div.style.overflowY = "scroll";
        div.style.width = "50px";
        div.style.height = "50px";

        document.body.append(div);
        let scrollWidth = div.offsetWidth - div.clientWidth;

        div.remove();

        return scrollWidth;
    }

    /**
     * Клонируем элемент фиксированной шапки.
     *
     * @returns {void}
     */
    createCloneHeader() {
        this.clone = this.el.cloneNode(true);
        this.clone.classList.add("header__fixed");
        this.header.appendChild(this.clone);
    }

    /**
     * Переключение видимости фиксированной шапки в зависомости от направления скролла страницы.
     * При скролле вверх - фиксированная шапка появляется.
     * При скролле вниз - скрывается.
     *
     * @returns {void}
     */
    toggleFixedMenuVisibility() {
        const scrollTop = document.body.scrollTop;
        const headerHeight = this.header.offsetHeight;

        if (scrollTop <= this.scrollPos && scrollTop > (headerHeight + HEADER_PADDING_BOTTOM)) {
            this.clone.classList.add("is-active");
            this.clone.style.right = this.getScrollbarWidth() + "px";
            this.header.style.marginTop = headerHeight;
            this.showLogoMini();
        } else if (scrollTop <= headerHeight && scrollTop >= 0) {
            this.hideFixedMenu();
        } else {
            this.hideFixedMenu();
        }
        this.scrollPos = scrollTop;
    }

    /**
     * Показать полный логотип.
     *
     * @returns {void}
     */
    showLogoMain() {
        this.logoMain.classList.remove("hidden");
        this.logoMini.classList.add("hidden");
    }

    /**
     * Показать мини-логотип.
     *
     * @returns {void}
     */
    showLogoMini() {
        this.logoMain.classList.add("hidden");
        this.logoMini.classList.remove("hidden");
    }

    /**
     * Скрыть фиксированное меню.
     *
     * @returns {void}
     */
    hideFixedMenu() {
        this.clone.classList.remove("is-active");
        setTimeout(() => {
            this.showLogoMain();
        }, ANIMATION_TIME);
    }
}

window.addEventListener("load", () => {
    document
        .querySelectorAll(".js-header-fixed")
        .forEach(el => new HeaderFixed(el));
});