import specialistData from "../../../../specialist-data.json";
import tableLessThreeMonths from "@partials/table-less-three-months.handlebars";
import tableMoreThreeMonths from "@partials/table-more-three-months.handlebars";
import desktopTable from "@partials/desktop-table.handlebars";
import {md} from "@main/consts/media";
/** Класс таблицы со стоимостью специалистов. */
class TableOfSpecialists {
    levels = []
    /**
     * Конструктор класса.
     *
     * @param {HTMLElement} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.list = this.el.querySelector(".js-table-block-list");
        this.switch = this.el.querySelector(".js-table-block-switch");

        this.createTable();
        this.bindEventListeners();
    }

    /**
     * Отслеживание событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.switch.addEventListener("change", () => {
            this.getValueSwitch();
            this.createTableOnMobile();
        });
    }

    /**
     * При расширении экрана до 768px создается таблица для мобильного, от 768px и более - для десктопа.
     *
     * @returns {void}
     */
    createTable() {
        if (window.innerWidth >= md) {
            this.createTableOnDesktop();
        } else {
            this.createTableOnMobile();
        }
    }

    /**
     * Получаем значение, выбрана ли длительнность аутстафа на срок более трех месяцев или нет.
     *
     * @returns {boolean} Выбрана ли длительнность аутстафа на срок более трех месяцев.
     */
    getValueSwitch() {
        return this.switch.checked;
    }

    /**
     * Преобразует 10000 -> 10 000 ₽.
     *
     * @param {string} price Строка для форматирования.
     * @returns {string} 10000 -> 10 000 ₽.
     */
    priceFormat = (price) => {
        const formatter = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
            maximumFractionDigits: 0
        });
        return formatter.format(Number(price));
    };

    /**
     * Преобразуем данные для вывода в таблицу.
     *
     * @param {JsonArray} data Данные о специалистах и их стоимости.
     * @returns {void}
     */
    updateDataForTable(data) {
        this.levels = [];
        data.levels.forEach(level => {
            level.level = level.level[0].toUpperCase() + level.level.slice(1); // middle => Middle

            level.price.forEach(price => {
                price.hour = this.priceFormat(price.hour);
                price.month = this.priceFormat(price.month);
            })
            this.levels.push(level);
        })
    }

    /**
     * В зависимости от длительности аутстафа создаем таблицу со стоимостью на мобильные устройства.
     *
     * @returns {void}
     */
    createTableOnMobile() {
        this.list.innerHTML = "";
        this.cloneSpecialistData = JSON.parse(JSON.stringify(specialistData));
        this.cloneSpecialistData.forEach(data => {
            this.updateDataForTable(data);
            
            const mobileTableContent = this.getValueSwitch() ?
                tableMoreThreeMonths({
                    data: {
                        shortName: data.shortName,
                        levels: this.levels,
                    },
                }) :
                tableLessThreeMonths({
                    data: {
                        shortName: data.shortName,
                        levels: this.levels,
                    },
                });

            this.list.insertAdjacentHTML("beforeend", mobileTableContent);
        })
    }

    /**
     * Создаем таблицу со стоимостью на desktop.
     *
     * @returns {void}
     */
    createTableOnDesktop() {
        this.list.innerHTML = "";
        this.cloneSpecialistData = JSON.parse(JSON.stringify(specialistData));

        this.cloneSpecialistData.forEach(data => {
            this.updateDataForTable(data);
            const desktopTableContent = desktopTable({
                data: {
                    shortName: data.shortName,
                    levels: this.levels,
                },
            });
            this.list.insertAdjacentHTML("beforeend", desktopTableContent);
        })
    }
}

document.querySelectorAll(".js-table-block").forEach(el => {
    new TableOfSpecialists(el)
});