import specialistData from "../../../../specialist-data.json"
/** Класс калькулятора для расчета стоимости специалистов. */
class Calculator {
    costList = []
    /**
     * Конструктор класса.
     *
     * @param {HTMLElement} el Элемент.
     */
    constructor(el) {
        this.el = el;
        this.form = this.el.querySelector(".js-calculator-form");
        this.fields = this.el.querySelectorAll(".js-calculator-fields");
        this.switch = this.el.querySelector(".js-calculator-switch");
        this.buttonsSpecialist = this.el.querySelectorAll(".js-radio-button-specialist");
        this.buttonsLevels = this.el.querySelectorAll(".js-radio-button-level");

        this.setCheckedFirstRadioButtonInList();
        this.setPriceAndPlash();
        this.bindEventListeners();
    }

    /**
     * Отслеживание событий.
     *
     * @returns {void}
     */
    bindEventListeners() {
        this.switch.addEventListener("change", () => {
            this.getValueSwitch();
            this.setPriceAndPlash();
        });

        this.buttonsSpecialist.forEach(button => {
            button.addEventListener("change", () => {
                this.getValueSelectedSpecialist();
                this.setPriceAndPlash();
            })
        })

        this.buttonsLevels.forEach(button => {
            button.addEventListener("change", () => {
                this.getValueSelectedLevel();
                this.setPriceAndPlash();
            })
        })

        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("js-book-specialist")) {
                const term = this.getValueSwitch() ? "более 3 месяцев" : "менее 3 месяцев";
                const message = `Забронировать специалиста "${this.getNameSelectedSpecialist()}" уровня ${this.getValueSelectedLevel()} на срок ${term}`;
                const inputsMessageInForm = document.querySelector(".js-message-upon-booking");
                const field = inputsMessageInForm.parentElement;

                field.classList.add("filled");
                inputsMessageInForm.innerHTML = message;
            }
        })
    }

    /**
     * Установить checked первому элементу в списке.
     *
     * @returns {void}
     */
    setCheckedFirstRadioButtonInList() {
        this.fields.forEach(field => {
            const firstRadioButtonBlock = field.firstElementChild;
            const radioButton = firstRadioButtonBlock.querySelector("input");
            radioButton.checked = true;
        })
    }

    /**
     * Получаем значение, выбрана ли длительнность аутстафа на срок более трех месяцев или нет.
     *
     * @returns {boolean} Выбрана ли длительнность аутстафа на срок более трех месяцев.
     */
    getValueSwitch() {
        return this.switch.checked;
    }

    /**
     * Получаем значение выбранного специалиста.
     *
     * @returns {string} Наименование специальности.
     */
    getValueSelectedSpecialist() {
        const selectedSpecialist = [...this.buttonsSpecialist].filter(button => button.checked);
        return selectedSpecialist[0].value;
    }

    /**
     * Получаем наименование выбранного специалиста.
     *
     * @returns {string} Наименование специальности.
     */
    getNameSelectedSpecialist() {
        const selectedSpecialist = [...this.buttonsSpecialist].filter(button => button.checked);
        const nameSpecialist = selectedSpecialist[0].nextElementSibling.innerHTML;
        return nameSpecialist;
    }

    /**
     * Получаем значение выбранного уровня специалиста.
     *
     * @returns {string} Уровень.
     */
    getValueSelectedLevel() {
        const selectedLevel = [...this.buttonsLevels].filter(button => button.checked);
        return selectedLevel[0].value;
    }

    /**
     * Создаем массив с почасовой и с помесячной оплатой специалиста.
     *
     * @returns {void}
     */
    createArrayHourlyAndMonthlyPrices() {
        this.costList = [];
        specialistData.forEach(item => {
            if (item.label === this.getValueSelectedSpecialist()) {
                const levelArray = item.levels
                    .filter(el => el.level === this.getValueSelectedLevel());
                const price = levelArray[0].price.filter(el => el.lessThreeMonths !== this.getValueSwitch());
                this.costList.push(price[0].hour);
                this.costList.push(price[0].month);
            }
        })
    }

    /**
     * Преобразует 10000 -> 10 000 ₽.
     *
     * @param {string} price Строка для форматирования.
     * @returns {string} 10000 -> 10 000 ₽.
     */
    priceFormat = (price) => {
        const formatter = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
            maximumFractionDigits: 0
        });
        return formatter.format(Number(price));
    };

    /**
     * Вывести почасовую и помесячную стоимость.
     * Показать плашку, информирующую о размере скидки, если отмечен чекбокс "Более 3 месяцев".
     *
     * @returns {void}
     */
    setPriceAndPlash() {
        this.createArrayHourlyAndMonthlyPrices();

        const prices = this.costList;
        const hourlyCostElement = this.el.querySelector(".js-calculator-hourly-cost");
        const monthlyCostElement = this.el.querySelector(".js-calculator-monthly-cost");
        const [hourlyCost, monthlyСost] = [this.priceFormat(prices[0]), this.priceFormat(prices[1])];
        hourlyCostElement.innerHTML = hourlyCost;
        monthlyCostElement.innerHTML = monthlyСost;

        const monthlyPaymentTitle = this.el.querySelector(".js-calculator-monthly-payment-title");
        const showAccentPlash = `Помесячная оплата
            <div class="accent-plash">
                <span class="d-lg-none">- 5%</span>
                <span class="d-none d-lg-block">Выгоднее</span>
            </div>`;

        if (this.getValueSwitch()) {
            monthlyPaymentTitle.innerHTML = "";
            monthlyPaymentTitle.insertAdjacentHTML("beforeend", showAccentPlash);
        } else {
            monthlyPaymentTitle.innerHTML = "Помесячная оплата";
        }
    }
}

document.querySelectorAll(".js-calculator").forEach(el => {
    new Calculator(el)
});