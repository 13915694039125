import {Input} from "@main/components/form/input";
import {WITHOUT_DIGITS_REGEX} from "@main/consts/regex";

const KEY_DIGIT_8 = 56;
const KEY_DIGIT_NUMPAD_8 = 104;
/** Класс маски для телефона. */
export class PhoneInput extends Input {
    /**
     * Конструктор класса.
     *
     * @param {object} props Параметры компонента.
     * @param {HTMLElement} props.container Родительский элемент.
     * @param {HTMLInputElement} props.input Элемент ввода.
     */
    constructor(props) {
        super(props);
    }

    /**
     * Фильтрует в строке числа.
     *
     * @returns {void}
     */
    getInputNumberValues() {
        return this.input.value.replace(WITHOUT_DIGITS_REGEX, "");
    }

    /**
     * Прослушиваем события в инпуте с телефоном.
     *
     * @returns {void}
     */
    bindEventListeners() {
        super.bindEventListeners();
        this.el.addEventListener("keydown", this.onKeyDown.bind(this));
        this.el.addEventListener("paste", this.onPaste.bind(this));
    }

    /**
     * Применение маски к телефону при вводе.
     *
     * @param {Event} e Событие, происходящее при вводе в инпуте.
     * @returns {void}
     */
    onInput(e) {
        super.onInput();
        const input = e.target;
        let inputNumbersValue = this.getInputNumberValues();
        let formattedInputValue = "";
        let selectionStart = input.selectionStart;

        if (!inputNumbersValue) {
            input.value = "";
            return;
        }

        if (input.value.length !== selectionStart) {
            if (e.data && WITHOUT_DIGITS_REGEX.test(e.data)) {
                input.value = inputNumbersValue;
            }

            return;
        }

        if (inputNumbersValue[0] === "9") {
            inputNumbersValue = "7" + inputNumbersValue;
        }

        formattedInputValue = "+7 ";

        if (inputNumbersValue.length > 1) {
            formattedInputValue += `(${inputNumbersValue.substring(1, 4)}`;
        }

        if (inputNumbersValue.length >= 5) {
            formattedInputValue += `) ${inputNumbersValue.substring(4, 7)}`;
        }

        if (inputNumbersValue.length >= 8) {
            formattedInputValue += `-${inputNumbersValue.substring(7, 9)}`;
        }

        if (inputNumbersValue.length >= 10) {
            formattedInputValue += `-${inputNumbersValue.substring(9, 11)}`;
        }

        input.value = formattedInputValue;
    }

    /**
     * Применение маски к телефону при каждом нажатии на клавиатуре.
     *
     * @param {Event} e Событие, происходящее при каждом нажатии клавиши.
     * @returns {void}
     */
    onKeyDown(e) {
        const input = e.target;

        if ((e.keyCode === KEY_DIGIT_8 || e.keyCode === KEY_DIGIT_NUMPAD_8) 
        && this.getInputNumberValues().length === 1) {
            input.value = "";
        }
    }

    /**
     * Применение маски к телефону при вставке скопированного номера.
     *
     * @param {Event} e Событие, происходящее при вставке скопированного номера.
     * @returns {void}
     */
    onPaste(e) {
        const pasted = e.clipboardData || window.clipboardData;
        const input = e.target;
        const inputNumbersValue = this.getInputNumberValues();

        if (pasted) {
            const pastedText = pasted.getData("Text");

            if (WITHOUT_DIGITS_REGEX.test(pastedText)) {
                input.value = inputNumbersValue;
            }
        }
    }
}
