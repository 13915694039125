import {Modal} from "@main/components/modal";
import {Form} from "@main/components/form";
import {isEnvDisableCaptcha} from "@main/utils/disable-captcha";
const TARGET_ID = 54304722;

/**
 * Компонент формы заявки.
 */
class FormRequest {
    /**
     * Создает компонент.
     *
     * @param {HTMLElement} el Элемент-обертка формы с заявкой.
     */
    constructor(el) {
        this.el = el;
         
        this.findElements();
        this.bindEventlisteners();
    }

    /**
     * Находит элементы компонента.
     *
     * @returns {void}
     */
    findElements() {
        this.formElement = this.el.querySelector("form");        
        this.form = new Form(this.formElement);
        this.btnSubmit = this.formElement.querySelector("button[type='submit']");
        this.firstField = this.formElement.querySelector(".js-form-first-field");
        this.modal = document.querySelector(".js-modal[data-modal-name='after-request']");
        this.modalDialog = this.modal.querySelector(".js-modal-dialog");
        this.modalMessage = this.modal.querySelector(".js-modal-message");
        this.modalMessageTitle = this.modalMessage.querySelector(".js-modal-title-message");
        this.modalMessageText = this.modalMessage.querySelector(".js-modal-text-message");
        this.modalMessageButton = this.modalMessage.querySelector(".js-modal-button-message");
    }

    /**
     * Привязывает колбеки событий.
     *
     * @returns {void}
     */
    bindEventlisteners() {
        this.btnSubmit.addEventListener("click", (e) => this.submitForm(e));
    }

    /**
     * Отправка формы с показом результата в модальном окне.
     *
     * @param {Event} e Событие, происходящее при отправке формы.
     * @returns {void}
     */
    submitForm = async (e) => {        
        e.preventDefault();
        if (!this.form.isValid) {
            this.firstField.scrollIntoView({behavior: "smooth", block: "start"});            
            return;
        }
        
        if (!isEnvDisableCaptcha(process.env)) {
            const token = await new Promise((resolve) => {
                grecaptcha.ready(async () => {
                    // noinspection JSVoidFunctionReturnValueUsed
                    resolve(await grecaptcha.execute(window.recaptchaKey, {
                        action: "submit",
                    }));
                });
            });

            this.formElement.querySelector("[name=g-recaptcha-response]").setAttribute("value", token);
        }

        if (typeof ym !== "undefined") {
            ym(TARGET_ID, "reachGoal", "otp_kont");
        }        

        try {
            const response = await Form.fetchForm(this.formElement, this.form.url);
            if (!response.success) {
                this.modalMessageTitle.innerHTML = response.error;
                this.modalMessageText.innerHTML = "Попробуйте ещё раз.";
                this.modalMessageButton.innerHTML = "Закрыть";
            } 
        } catch {
            this.modalMessageTitle.innerHTML = "Извините, произошла ошибка. ";
            this.modalMessageText.innerHTML = "Попробуйте ещё раз.";
            this.modalMessageButton.innerHTML = "Закрыть";
        }
        
        this.showModal();
        this.modalDialog.classList.add("is-send");
    }

    /**
     * Показать окно с результатом отправки формы.
     *
     * @returns {void}
     */
    showModal() {
        Modal.open(this.modal);
    }
}

window.addEventListener("load", () => {
    document
        .querySelectorAll(".js-request")
        .forEach(el => new FormRequest(el));
});
