/**
 * Компонент переключения вкладок.
 */
class Tabs {
    /**
     * Вкладки компонента.
     *
     * @type {{String : {button: HTMLButtonElement, content: HTMLElement}}} объект с существующими вкладками,
     *  группирует кнопку переключения и сам контент этой вкладки
     * @private
     */
    tabs;

    /**
     * @type {{button: HTMLButtonElement, content: HTMLElement}} элементы активной вкладки
     * @private
     */
    active;

    /**
     * Создает компонент переключения вкладок.
     *
     * @param {HTMLElement} el - Родительский элемент кнопок вкладок.
     */
    constructor(el) {
        this.el = el;
        this.findElements();
        this.bindEventListeners();
    }

    /**
     * Поиск вкладок, соответствующих кнопкам и сохранение в объекте.
     *
     * @returns {void}
     */
    findElements() {
        const tabContentGroup = document.querySelector(
            `.js-tabs-group[data-tab-group="${this.el.getAttribute("data-group-target")}"]`
        );
        const tabContentList = Array.from(tabContentGroup.querySelectorAll(".js-tabs-content"));
        const tabButtons = Array.from(this.el.querySelectorAll(".js-tabs-button"));

        this.tabs = tabButtons.reduce((acc, tabButton) => {
            const key = tabButton.dataset.entity;
            const linkedTabContent = tabContentList.find((tabContent) => tabContent.dataset.entity === key);
            if (!linkedTabContent) {
                return acc;
            }

            return {
                ...acc,
                [key]: {
                    button: tabButton,
                    content: linkedTabContent,
                },
            };
        }, {});

        this.active = {
            button: this.el.querySelector(".js-tabs-button.active"),
            content: tabContentGroup.querySelector(".js-tabs-content.active"),
        };
    }

    /**
     * Вешает коллбэк нажатия на кнопки вкладок.
     *
     * @returns {void}
     */
    bindEventListeners() {
        Object.values(this.tabs).forEach((value) => {
            value.button.addEventListener("click", () => {
                this.active.button?.classList.remove("active");
                this.active.content?.classList.remove("active");

                this.active = value;
                this.active.button?.classList.add("active");
                this.active.content?.classList.add("active");
            })
        });
    }
}

document.querySelectorAll(".js-tabs").forEach((el) => new Tabs(el));